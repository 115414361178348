<template>
  <base-layout>
    <TripIndex
      :fst-id="`trips`"
      :pageTitle="$t('components.tripManagement.headline')"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import TripIndex from '@/composites/trip/index/Index.vue'

export default {
  name: 'Trips',
  components: {
    BaseLayout,
    TripIndex,
  },

  methods: {},
}
</script>

<style lang="scss">
@media only screen and (max-width: 500px) {
  .filteroptions {
    margin-top: 10px;
    margin-left: 0 !important;
  }
}
</style>
